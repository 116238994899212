import { initializeApp } from "firebase/app";


const firebaseConfig = {
    apiKey: "AIzaSyD92BXYOsCWFHTF0YSZG1L-C4o0hT0mDq8",
    authDomain: "abd-developer.firebaseapp.com",
    projectId: "abd-developer",
    storageBucket: "abd-developer.appspot.com",
    messagingSenderId: "530958724302",
    appId: "1:530958724302:web:b112dc2586df9e36c3749f",
    measurementId: "G-4C5P6L1701"
  };
  


const app = initializeApp(firebaseConfig);

export default app;